import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ParallaxImage {
	constructor({ element }) {
		this.element = element;
		this.image = this.element.querySelector("img");

		this._onScroll();
	}

	_onScroll() {
		gsap.to(this.image, {
			yPercent: -10,
			ease: "none",
			scrollTrigger: {
				trigger: this.element,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
			},
		});
	}
}
