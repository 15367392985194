import ParallaxImage from "./defaults/ParallaxImage";
import TextReveal from "./defaults/TextReveal";
import FadeUp from "./defaults/FadeUp";

const animations = [
	{
		selector: '[data-animation="parallax-image"]',
		module: (item) => new ParallaxImage({ element: item }),
	},
	{
		selector: '[data-animation="text-reveal"]',
		module: (item) => new TextReveal({ element: item }),
	},
	{
		selector: '[data-animation="fade-up"]',
		module: (item) => new FadeUp({ element: item }),
	},
];

animations.forEach((animation) => {
	const items = [...document.querySelectorAll(animation.selector)];
	if (!items.length > 0) return;

	items.forEach((item) => animation.module(item));
});
