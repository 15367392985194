import { gsap } from "gsap";
import SplitType from "split-type";

const callToActions = [...document.querySelectorAll(".header__cta > *, .header__languages")];
const logo = document.querySelector(".header__logo");
const accordionLines = [...document.querySelectorAll(".accordion__line")];
const accordionTitles = [...document.querySelectorAll(".accordion__title")];
const accordionIcons = [...document.querySelectorAll(".accordion__icon")];
const tags = [...document.querySelectorAll(".tag")];

const heroTitle = document.querySelector(".hero__title");
const heroButton = document.querySelector(".hero__button");
const splitHeroTitle = new SplitType(heroTitle, { types: "words, chars" });

const IntroAnimation = () => {
	const ANIMATION_DURATION = 3.5;
	const tl = gsap.timeline({
		delay: 0.7,
		defaults: {
			duration: ANIMATION_DURATION,
			ease: "power3.out",
			yPercent: 0,
			y: 0,
		},
	});

	// prettier-ignore
	tl
	.set(splitHeroTitle.chars, {y: 24, autoAlpha: 0})
	.set([heroTitle], { autoAlpha: 1 })
	.to(splitHeroTitle.chars, {
		autoAlpha: 1,
		stagger: 0.015,
		delay: 1
	})
	.to(heroButton, {
		autoAlpha: 1,
		y: 0,
		yPercent: 0
	}, 0.5)
	.to([logo, callToActions], {
		stagger: 0.1,
		autoAlpha: 1,
	}, 0.2)
	.to([accordionTitles, tags], {
		x: 0,
		xPercent: 0,
		autoAlpha: 1,
		stagger: 0.2,
	}, 0.2)
	.to(accordionLines, {
		scaleX: 1,
		stagger: 0.1,
	}, 0.2)
	.to(accordionIcons, {
		autoAlpha: 1,
		x: 0,
		xPercent: 0,
		stagger: 0.2,
	}, 1.5)
	.duration(ANIMATION_DURATION);
};

export default IntroAnimation;
