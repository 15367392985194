import { gsap } from "gsap";
import SplitType from "split-type";
import Observer from "./Observer";

export default class TextReveal extends Observer {
	constructor({ element }) {
		super({ element });

		this.delay = element.getAttribute("data-delay");
		this.duration = element.getAttribute("data-duration");
		this.texts = [];

		this.element = element;
		this.target = this._splitLines(this.element);
	}

	_splitLines(textElement) {
		if (textElement.children.length >= 1) {
			const texts = [];
			[...textElement.children].forEach((child) => {
				const splitted = new SplitType(child, { types: "words" });
				texts.push(new SplitType(splitted.words, { types: "words" }));
			});

			return texts;
		}

		const splitted = new SplitType(textElement, { types: "words" });
		return new SplitType(splitted.words, { types: "words" });
	}

	onEnter() {
		if (Array.isArray(this.target)) {
			this.target.forEach((target) => {
				this.animation(target.words);
			});
		} else {
			this.animation(this.target.words);
		}
	}

	animation(target, delay = 0, duration = 1.5, stagger = 0.0075) {
		return gsap.to(target, {
			delay: delay,
			duration: duration,
			y: "0%",
			yPercent: 0,
			rotation: 0,
			stagger: stagger,
			autoAlpha: 1,
			ease: "power3.out",
		});
	}
}
