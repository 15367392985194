import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ColorGrid {
	constructor({ wrapper, columns = 11, width = 800, index = 0 }) {
		this.wrapper = wrapper;
		this.columns = 11;
		this.rows = 11;
		this.dots = [];
		this.colors = ["blue", "purple", "yellow", "green"];
		this.colorIndex = 0;

		this.wrapperSize = width;
		this.cellWidth = this.wrapperSize / this.columns;
		this.cellHeight = this.wrapperSize / this.rows;
		this.totalDots = this.columns * this.rows;
		this.timelineForward = true;

		this._setInitialStates();
		this._createDots();
		//this._setGridPosition();
		this._setGridColor();
		this._createTimeline();
	}

	_setInitialStates() {
		this.wrapper.style.width = `${this.wrapperSize}px`;
		this.wrapper.style.height = `${this.wrapperSize}px`;
	}

	_createDots() {
		for (let i = 0; i < this.totalDots; i++) {
			const dot = document.createElement("div");
			dot.classList.add("dot");
			dot.style.width = `${this.cellWidth}px`;
			dot.style.height = `${this.cellHeight}px`;
			this.wrapper.appendChild(dot);
			this.dots.push(dot);
		}
	}

	_setGridColor() {
		if (this.colorIndex >= this.colors.length) {
			this.colorIndex = 0;
		}

		this.color = this.colors[this.colorIndex];
		this.colorIndex++;
	}

	_setGridPosition() {
		const { width, height } = this.wrapper.getBoundingClientRect();

		const maxX = window.innerWidth - width;
		const maxY = window.innerHeight - height;

		const randomX = Math.floor(Math.random() * maxX);
		const randomY = Math.floor(Math.random() * maxY);

		gsap.set(this.wrapper, {
			left: randomX,
			top: randomY,
		});
	}

	_createTimeline() {
		const onRepeat = () => {
			if (!this.timelineForward) {
				this._setGridColor();
				//this._setGridPosition();

				this.wrapper.setAttribute("data-color", this.color);
			}

			this.timelineForward = !this.timelineForward;
		};

		const tl = gsap.timeline({
			repeat: -1,
			yoyo: true,
			ease: "expo.inOut",
			onRepeat: onRepeat,
			delay: 1,
		});

		tl.to(this.dots, {
			opacity: (index) => {
				const { distance } = this._getGridCenter(index);
				const opacity = 1 - distance * 0.2;
				return opacity < 0 ? 0 : opacity;
			},
			scale: 1.01,
			duration: 2,
			stagger: {
				grid: "auto",
				from: "center",
				each: 0.1,
			},
		});
	}

	_getGridCenter(index) {
		const middleRowIndex = Math.floor(this.rows / 2);
		const middleColIndex = Math.floor(this.columns / 2);

		const rowIndex = Math.floor(index / this.columns);
		const colIndex = index % this.columns;

		const distanceFromMiddleX = colIndex - middleColIndex;
		const distanceFromMiddleY = middleRowIndex - rowIndex;

		const angle = Math.atan2(distanceFromMiddleY, distanceFromMiddleX);
		const angleDegrees = (angle * 180) / Math.PI;

		return {
			distance: Math.sqrt(distanceFromMiddleX ** 2 + distanceFromMiddleY ** 2),
			angle: angleDegrees,
		};
	}
}
