import "@/styles/index.scss";
import "./utils/scroll";
import "./animation";
import "./animation/Progress";
import "./modules/MobileNav";
import "lazysizes";

import Stats from "stats.js";
import Lenis from "@studio-freight/lenis";

import "./modules/Accordion";
import IntroAnimation from "./animation/Intro";
import ColorGrid from "./animation/ColorGrid";

const IS_DEVELOPMENT = import.meta.env.DEV;

class App {
	constructor() {
		document.body.style.opacity = "1";

		if (IS_DEVELOPMENT && window.location.search.indexOf("fps") > -1) {
			this._createStats();
		}

		this._createIntro();
		this._createColorGrid();
		this._addEventListeners();
		this._render();
	}

	_createStats() {
		this.stats = new Stats();
		document.body.appendChild(this.stats.dom);
	}

	_createLenis() {
		const wrapper = document.querySelector(".page-content");

		this.lenis = new Lenis({
			wrapper: wrapper,
			lerp: 0.15,
		});

		window.LENIS = this.lenis;
	}

	_createPreloader() {
		this.preloader = new Preloader();
		this.preloader.once("completed", this.onLoadingFinished.bind(this));
	}

	_createIntro() {
		IntroAnimation();
	}

	_createColorGrid() {
		const colorGrids = [...document.querySelectorAll(".dots")];
		colorGrids.forEach((grid, index) => {
			new ColorGrid({
				wrapper: grid,
				index: index,
			});
		});
	}

	_onMouseMove(event) {
		if (this.webGL && this.webGL.onMouseMove) {
			this.webGL.onMouseMove(event);
		}
	}

	_onResize() {
		if (this.webGL) {
			this.webGL.onResize();
		}
	}

	_addEventListeners() {
		window.addEventListener("resize", this._onResize.bind(this));
		window.addEventListener("mousemove", this._onMouseMove.bind(this));
	}

	_render(time) {
		if (this.stats) {
			this.stats.begin();
		}

		if (this.lenis) {
			this.lenis.raf(time);
		}

		if (this.webGL && this.webGL.update) {
			this.webGL.update({
				lenis: this.lenis,
			});
		}

		window.requestAnimationFrame(this._render.bind(this));
	}
}

new App();

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
