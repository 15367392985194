import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const progressBar = document.querySelector(".progress");
ScrollTrigger.create({
	trigger: "body",
	start: "top top",
	end: "bottom top",
	ease: "none",
	onUpdate({ progress }) {
		progressBar.style.transform = `scaleX(${progress})`;
	},
});
