import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const accordions = Array.from(document.querySelectorAll(".accordion"));
let currentAccordion = null;

const resetAccordions = () => {
	accordions.forEach((accordion) => {
		accordion.classList.remove("active");
	});
	document.removeEventListener("keydown", checkKeys);
};

const openAccordion = (accordion) => {
	accordion.classList.add("active");
	currentAccordion = accordion;
};

const closeAccordion = (accordion) => {
	accordion.classList.remove("active");
	currentAccordion = null;
};

const checkKeys = (event) => {
	if (event.keyCode === 27) {
		currentAccordion = null;
		resetAccordions();
	}
};

const addKeyListener = () => document.addEventListener("keydown", checkKeys);
const removeKeyListener = () => document.removeEventListener("keydown", checkKeys);

const onAccordionClick = (clickedAccordion) => {
	if (clickedAccordion === currentAccordion) {
		closeAccordion(clickedAccordion);
		removeKeyListener();
	} else {
		resetAccordions();
		addKeyListener();
		openAccordion(clickedAccordion);
	}
};

accordions.forEach((accordion, index) => {
	accordion.addEventListener("click", () => onAccordionClick(accordion));
});
